.apicleanarchitecture-details-container {
    padding: 10px;
    font-size: 24px;
}

.apicleanarchitecture-details-card {
    padding: 10px;
}

.apicleanarchitecture-goback-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.apicleanarchitecture-goback-container h2 {
    margin-left: 15px;
    font-size: 1em;
    color: #263238;
    letter-spacing: -0.015em;
    text-transform: uppercase;
}

.apicleanarchitecture-goback-container svg {
    margin-left: 5px;
    transform: rotate(-180deg);
    color: #407bff;
}

.apicleanarchitecture-img-container{
    text-align: center;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 1px solid #e1e1e1;
    padding: 10px;
}

.apicleanarchitecture-img-container img{
    width: 90%;
    height: auto;
}

.apicleanarchitecture-name-subtitle-container{
    margin-bottom: 20px;
}

.apicleanarchitecture-name-subtitle-container h1{
    color: #263238;
    letter-spacing: -0.015em;
    font-size: 1.1em;
    margin-bottom: 15px;
}

.apicleanarchitecture-name-subtitle-container p{
    color: #9e9e9e;
    letter-spacing: -0.015em;
    font-size: 0.6em;
}

.apicleanarchitecture-name-subtitle-container mark{
    border-radius: 10px;
    padding: 0.5px;
}

@media (min-width: 576px) {
    .apicleanarchitecture-details-container {
        font-size: 32px;
        padding: 20px;
    }

    .apicleanarchitecture-details-card {
        padding: 20px;
    }
}

@media (min-width: 992px) {
    .apicleanarchitecture-details-container {
        padding: 40px;
    }

    .apicleanarchitecture-details-card {
        padding: 40px;
    }

}

@media (min-width: 1400px) {
    .apicleanarchitecture-img-container img{
        width: 1080px;
        height: auto;
    }

}

@media (min-width: 1600px) {
    .apicleanarchitecture-details-container {
        padding: 50px;
    }
}



@media (min-width: 1920px) {
    .apicleanarchitecture-details-container {
        padding: 60px;
    }
}