.project-container {
    font-size: 18px;
}

.project-title-container {
    margin-bottom: 20px;
}

.project-title-container h1 {
    font-size: 1em;
    letter-spacing: -0.015em;
    color: #263238;
}

.project-card-container {
    font-size: 18px;
}

@media (min-width: 576px ){
    .project-container {
        font-size: 30px;
    }

    .project-card-container {
        font-size: 18px;
    }
}