@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;700&display=swap');

* {
    font-family: 'Fira Code', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    margin-bottom: 0;
}

a,
a:hover {
    text-decoration: none;
    color: unset;
}

.base-card {
    background-color: #fff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}