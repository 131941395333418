.ds-vendas-details-container {
    padding: 10px;
    font-size: 24px;
}

.ds-vendas-details-card {
    padding: 10px;
}

.ds-vendas-goback-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.ds-vendas-goback-container h2 {
    margin-left: 15px;
    font-size: 1em;
    color: #263238;
    letter-spacing: -0.015em;
    text-transform: uppercase;
}

.ds-vendas-goback-container svg {
    margin-left: 5px;
    transform: rotate(-180deg);
    color: #407bff;
}

.ds-vendas-img-container {
    text-align: center;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 1px solid #e1e1e1;
    padding: 10px;
}

.ds-vendas-img-container img {
    width: 80%;
    height: auto;

}

.ds-vendas-name-subtitle-container {
    margin-bottom: 20px;
}

.ds-vendas-name-subtitle-container h1 {
    color: #263238;
    letter-spacing: -0.015em;
    font-size: 1.1em;
}

.description-container {
    border-radius: 20px;
    border: 1px solid #e1e1e1;
    padding: 10px;
}

.description-container h2 {
    color: #9e9e9e;
    font-size: 0.8em;
    letter-spacing: -0.015em;
    margin-bottom: 20px;
}

.description-container p {
    color: #9e9e9e;
    letter-spacing: -0.015em;
    font-size: 0.6em;
}

.techs-title-container {
    color: #263238;
    letter-spacing: -0.015em;
    font-size: 1.1em;
    margin: 30px;
}

.techs-container {
    margin-top: 20px;
}

.techs-container p {
    color: #9e9e9e;
    letter-spacing: -0.015em;
    font-size: 0.6em;
    margin: 0;
    margin-left: 10px;
}

.techs-container-table {
    margin: 20px;
}

.techs-container-table th {
    color: #fff;
    padding: 10px;
    font-size: 20px;
    border-radius: 10px 0 0 10px;
}

.techs-container-table td {
    padding: 5px;
    border-top: 1px solid #f0ebeb;
    border-bottom: 1px solid #f0ebeb;
    border-radius: 30px;
}


@media (min-width: 576px) {
    .ds-vendas-details-container {
        font-size: 32px;
        padding: 20px;
    }

    .ds-vendas-details-card {
        padding: 20px;
    }
}

@media (min-width: 992px) {
    .ds-vendas-details-container {
        padding: 40px;
    }

    .ds-vendas-details-card {
        padding: 40px;
    }

    .ds-vendas-img-container img {
        width: 60%;
    }

    .description-container {
        padding: 40px;
    }

}


@media (min-width: 1200px) {
    .ds-vendas-img-container img {
        width: 80%;
        height: auto;
    }

    .description-container {
        min-height: 70%;
    }
}

@media (min-width: 1400px) {
    .ds-vendas-img-container img {
        height: 480px;
        width: auto;
    }

    .description-container {
        min-height: 500px;
    }
}

@media (min-width: 1400px) {
    .ds-vendas-img-container img {
        height: 480px;
        width: auto;
    }

    .description-container {
        min-height: 500px;
    }
}

@media (min-width: 2560px) {
    .ds-vendas-img-container img {
        height: 480px;
        width: auto;
    }

    .description-container {
        min-height: 500px;
    }

}