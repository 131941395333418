.cartificate-container{
    font-size: 18px;
}

.cartificate-title-container {
    margin-bottom: 20px;
}

.cartificate-title-container h1 {
    font-size: 1em;
    letter-spacing: -0.015em;
    color: #263238;
}

.cartificate-card-container {
    font-size: 18px;
}

@media (min-width: 576px ){
    .cartificate-container {
        font-size: 30px;
    }

    .cartificate-card-container {
        font-size: 18px;
    }
}