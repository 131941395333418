.license-card {
    margin-top: 30px;
    margin-bottom: 30px;
}

.card-container {
    padding: 30px 20px 30px 20px;
}

.card-container h1,
span {
    color: #263238;
    letter-spacing: -0.015em;
    font-size: 1.1em;
}

.card-container span {
    font-size: 25px;
}

.card-container p {
    width: 100%;
    height: auto;
    color: #9e9e9e;
    letter-spacing: -0.015em;
    font-size: 0.6em;
}