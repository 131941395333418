.contact-details-container {
    font-size: 24px;
    padding: 10px;
}

.contact-details-container p {
    color: #9e9e9e;
    letter-spacing: -0.015em;
    font-size: 0.6em;
}

.contact-details-card {
    padding: 10px;
}

.goback-container-contact {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.goback-container-contact h2 {
    margin-left: 15px;
    font-size: 1em;
    color: #263238;
    letter-spacing: -0.015em;
    text-transform: uppercase;
}

.goback-container-contact svg {
    margin-left: 5px;
    transform: rotate(-180deg);
    color: #407bff;
}

.title-contact-details {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
}

.title-contact-details h1 {
    margin-bottom: 50px;
}

.title-contact-details h3 {
    margin-bottom: 15px;
}

.contact-details-socials {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 50px;
}

.contact-details-socials-icons {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

}

.contact-details-socials-icons .teste {
    margin: 20px;

}

.contact-details-socials svg {
    height: auto;
    width: 30px;
}

.contact-details-socials p {
    color: #9e9e9e;
    letter-spacing: -0.015em;
    font-size: 0.6em;
}

.contact-details-socials span {
    margin-left: 10px;
    color: #9e9e9e;
    letter-spacing: -0.015em;
    font-size: 0.6em;
}


@media (min-width: 576px) {
    .contact-details-container {
        font-size: 32px;
        padding: 20px;
    }

    .contact-details-card {
        padding: 20px;
    }
}

@media (min-width: 992px) {

    .contact-details-socials-icons {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
    }

    .contact-details-container {
        padding: 40px;
    }

    .contact-details-card {
        padding: 40px;
    }

    .contact-details-socials-icons .teste {
        margin: 10px;

    }

    .contact-details-socials span {
        font-size: 0.5em;
    }
}

@media (min-width: 1200px){
    .title-contact-details h1 {
        font-size: 1em;
    }

    .content-contact {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-details-socials-icons .teste {
        margin: 30px;
    }
}