.about-details-container {
    padding: 10px;
    font-size: 24px;
}

.about-details-card {
    padding: 10px;
}

.goback-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.goback-container h2 {
    margin-left: 15px;
    font-size: 1em;
    color: #263238;
    letter-spacing: -0.015em;
    text-transform: uppercase;
}

.goback-container svg {
    margin-left: 5px;
    transform: rotate(-180deg);
    color: #407bff;
}

.img-container {
    text-align: center;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 1px solid #e1e1e1;
    padding: 10px;
}

.img-container img {
    width: 80%;
    height: auto;

}

.name-subtitle-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}

.name-subtitle-container h1 {
    color: #263238;
    letter-spacing: -0.015em;
    font-size: 1.1em;
}

.name-subtitle-container h2 {
    margin-bottom: 20px;
}

.description-container {
    border-radius: 20px;
    border: 1px solid #e1e1e1;
    padding: 10px;
}

.description-container h2 {
    color: #9e9e9e;
    font-size: 0.8em;
    letter-spacing: -0.015em;
    margin-bottom: 20px;
}

.description-container p {
    color: #9e9e9e;
    letter-spacing: -0.015em;
    font-size: 0.6em;
}


.name-subtitle-container svg {
    margin: 5px;
    width: 50px;
    height: 50px;
    margin-bottom: 0;
}


@media (min-width: 576px) {
    .about-details-container {
        font-size: 32px;
        padding: 20px;
    }

    .about-details-card {
        padding: 20px;
    }

    .name-subtitle-container h2 {
        margin-top: 20px;
    }

    .icons {
        margin-bottom: 30px;
    }
}

@media (min-width: 768px) {
    .img-container{
        padding: 40px;
    }
}

@media (min-width: 992px) {
    .about-details-container {
        padding: 40px;
    }

    .about-details-card {
        padding: 40px;
    }

    .img-container img {
        width: 60%;
    }

    .description-container {
        padding: 40px;
    }

    .icons svg {
        width: 80px;
        height: 80px;
    }

    .name-subtitle-container h2 {
        font-size: 32px;
    }

}


@media (min-width: 1200px) {
    .img-container img {
        width: 80%;
        height: auto;
    }

    .description-container {
        min-height: 70%;
    }

    .icons svg {
        margin-bottom: 30px;
     }
}

@media (min-width: 1400px) {
    .img-container img {
        height: 480px;
        width: auto;
    }

    .description-container {
        min-height: 500px;
    }

}

@media (min-width: 2560px) {
    .img-container img {
        height: 480px;
        width: auto;
    }

    .description-container {
        min-height: 500px;
    }

}